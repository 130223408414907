import { Component } from "react";
import "./navbar.css"

class Navbar extends Component {
    state = { clicked: false };
    handleClick = () => {
        this.setState({ clicked: !this.state.clicked })
    }
    render() {

        return <section>
            <nav >
                <img id="me" src="./images/Me.png" alt="My vector"></img>
                <div>
                    <ul id="navbar" className={this.state.clicked ? "#navbar" : "#navbar active"}>
                        <li><a href="#about">About</a></li>
                        <li><a href="#projects">Projects</a></li>
                        <li><a href="#home">Articles</a></li>
                        <li><a href="#contact">Contact</a></li>
                    </ul>
                </div>
                <div id="mobile" onClick={this.handleClick}>
                    {/* <i className="fas fa-bars"> </i>
                <i className="fas fa-times"> </i> */}
                    <i id="bar" className={this.state.clicked ? 'fas fa-times' : 'fas fa-bars'}> </i>
                </div>
            </nav>
        </section >
    }
}

export default Navbar;