import React from "react";
import ReactDOM from 'react-dom/client';
import './index.css';
import Navbar from "./components/navbar";

const projects = [
    {
        image: './images/ReportAI.png',
        title: 'Report AI',
        description: 'A Chrome Extension that can summarise, query and show web page rating',
        id: 1,
        github: "https://github.com/gobbledy-gook/report.ai",
        link: "https://github.com/gobbledy-gook/report.ai"
    },
    {
        image: './images/dostean.png',
        title: 'Dostean Website',
        description: "A front-end website for a local cafe in Surat as part of GWoC'23.",
        id: 2,
        github: 'https://github.com/gobbledy-gook/DOSTEAn',
        link: "https://www.dostean.netlify.app"
    },
    {
        image: './images/dostean.png',
        title: 'VSCode Extensions',
        description: 'VSCode Extensions to help programmar live happily :)',
        id: 3,
        github: 'https://github.com/Mozart-dotSlash/vscextension-manager',
        link: "https://github.com/Mozart-dotSlash/vscextension-manager"
    },
    {
        image: './images/dostean.png',
        title: 'Jukebox',
        description: 'A web-based tool to find the song for which you incomplete information.',
        id: 4,
        github: 'https://github.com/gobbledy-gook/Jukebox',
        link: "https://github.com/gobbledy-gook/Jukebox"
    },
    {
        image: './images/dostean.png',
        title: 'S.W.A.G Tone Analyser',
        description: 'Is a text hateful or not, to find out if the user had malicious intentions.',
        id: 5,
        github: 'https://github.com/gobbledy-gook/S.W.A.G-Tone-Analyser',
        link: "https://gobbledy-gook-s-w-a-g-tone-analyser-swag-tone-analyser-658l3l.streamlitapp.com/"
    },
]

const socials = [
    {
        src: "./images/gmail.png",
        alt: "Gmail",
        href: "https://mail.google.com/mail/?view=cm&fs=1&to=garvit.shah3@gmail.com"
    },
    {
        src: "./images/linkedin.png",
        alt: "Linkedin",
        href: "https://www.linkedin.com/in/garvit-shah-1287001b8/"
    },
    {
        src: "./images/github-sign.png",
        alt: "Github",
        href: "https://github.com/gobbledy-gook"
    },
    {
        src: "./images/instagram.png",
        alt: "Instagram",
        href: "https://www.instagram.com/garvitshah._/"
    },
]

const tech = [
    {
        src: "./images/tech/python.png",
        alt: "",
        href: "#"
    },
    {
        src: "./images/tech/c.png",
        alt: "",
        href: "#"
    },
    {
        src: "./images/tech/letter-c.png",
        alt: "",
        href: "#"
    },
    {
        src: "./images/tech/css.png",
        alt: "",
        href: "#"
    },
    {
        src: "./images/tech/html-5-2.png",
        alt: "",
        href: "#"
    },
    {
        src: "./images/tech/pocoo_flask_logo_icon_168045.png",
        alt: "",
        href: "#"
    },
    {
        src: "./images/tech/django-logo-negative.png",
        alt: "",
        href: "#"
    },
    {
        src: "./images/tech/mysql.png",
        alt: "",
        href: "#"
    },
    {
        src: "./images/tech/Latex-logo-5EAE2E278A-seeklogo.com.png",
        alt: "",
        href: "#"
    },
]

const Main = () => {
    return <section>
        <Navbar />
        <Backdrop />
        <About />
        <ProjectList />
        <ContactMe />
        <Footer />
    </section >
}

const Backdrop = () => {
    return <div className="lines">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
    </div>
}


const About = (props) => {
    return <section id="about" className="about sectionContainer">
        <h2 className="bookmark">Current: CSE Undergrad</h2>
        <p>
            Heyo 🤟🏻 I'm Garvit, a student at NIT Surat. I'm experimenting with my skills on building
            utility products. These projects are the result of my participation in some of the hackathons as a team. Currently I am learning
            about cryptography and I am to open to collaborate on a project if it interests me.
        </p>
        <div className="socialContainer">
            {socials.map((social) => {
                return <div className="social">
                    <a href={social.href} target="_blank"><img src={social.src} alt={social.alt}></img></a>
                </div>
            })}
        </div>
        <h3 style={{ margin: "10vh 2vh 2vh 2vh", fontSize: "1.3rem", fontWeight: "normal" }}>Technologies that I work with</h3>
        <div className="socialContainer">
            {tech.map((tech) => {
                return <div className="tech">
                    <a href={tech.href} target="_blank"><img src={tech.src} alt={tech.alt}></img></a>
                </div>
            })}
        </div>
    </section >
}


const ProjectList = () => {
    return <section className="sectionContainer" id="projects">
        <h2>Projects</h2>
        <div className="projectList">
            {projects.map((project) => {
                return <Project project={project} />
            })}
        </div>
    </section >
}

const Project = (props) => {
    const { title, description, image } = props.project;
    const toGithub = (e) => {
        e.preventDefault()
        window.open(props.project.github, '_blank');
    }
    const toProject = (e) => {
        e.preventDefault()
        window.open(props.project.link, '_blank');
    }
    return (
        <article className="project">
            <img src={image} alt={title} />
            <h3>{title}</h3>
            <h4>{description}</h4>
            <div className="optionsContainer">
                <button className="tagButton" onClick={toGithub}>Github</button>
                <button className="tagButton" onClick={toProject}>Visit</button>
            </div>
        </article>
    )
}


const ContactMe = () => {
    const handleButtonClick = (e) => {
        e.preventDefault()
        alert("Message to be emailed.")
    }
    return <section id="contact" className="sectionContainer">
        <h2>Send a Message</h2>
        <form>
            <div className="formContainer">
                <input type="text" name="firstName" placeholder="First Name"></input>
                <input type="text" name="lastName" placeholder="Last Name"></input>
                <input type="text" name="email" placeholder="Email"></input>
            </div>
            <div className="formContainer">
                <input type="text" name="message" placeholder="Message"></input><br></br>
            </div>
            <button className="submitButton" onClick={handleButtonClick} type="sumbit">Send Message</button>
        </form>
    </section >
}


const Footer = () => {
    return <section className="foot">
        All rights reserved © 2023
    </section>
}
// const Image = () => <img width="20%" src="./images/Book1.jpeg" />
// const Title = () => {
//     return <h2>Atomic Habits</h2>
// }
// const Author = () => (
//     <h4 style={{
//         color: '#617d98', marginTop: '0.5rem',
//         fontSize: '0.75rem'
//     }}>
//         Carl NewPort
//     </h4>
// )

// First Component
// function Greeting() {
//     return <div>
//         <Person />
//         <Message />
//     </div>
// }

// const Person = () => <h2>John Boi</h2>
// const Message = () => <p>This is new.</p>

// This is also a method but avoided and instead JSX is used.
// const Greeting = () => {
//     return React.createElement('h2', {}, 'hello world')
// }

const root = ReactDOM.createRoot(document.getElementById
    ('root'))

root.render(<Main></Main>)    